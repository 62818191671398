import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserSettings, UserSettingUpdate } from "@app/models/notification/notification.class";
import { environment } from "@environments/environment";

@Injectable()
export class NotificationService {
  private uri: string = `${environment.routes.apiEndpoint}/notification`;
  constructor(private http: HttpClient) { }

  public getUserSettings() {
    return this.http.get<UserSettings>(`${this.uri}/userSettings`);
  }

  public updateUserSettings(userSetting: UserSettingUpdate) {
    return this.http.patch<UserSettingUpdate>(`${this.uri}/userSettings`, userSetting);
  }
}
